import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

import PageComponentGroups from "../components/PageComponentGroups"

import Search from "../components/Search"

const IndexPage = props => {
  const { components, seo } = props.data

  const {
    pageContext: {
      postSearchData: { allPosts, options },
    },
  } = props

  return (
    <Layout posts={allPosts} engine={options}>
      <Seo
        title={seo.pageSeoData.swbThemeMetaTitle}
        description={seo.pageSeoData.swbThemeDescription}
        // metaImg={seo.pageSeoData.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      {/* <Search posts={allPosts} engine={options} /> */}
      <PageComponentGroups components={components} />
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    seo: wpPage(slug: { eq: "home" }) {
      pageSeoData {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    components: wpPage(slug: { eq: "home" }) {
      acfMainTemplateFields {
        pageComponents {
          ... on WpPage_Acfmaintemplatefields_PageComponents_TitleSection {
            fieldGroupName
            title
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_Slider {
            fieldGroupName
            slider {
              buttonType
              buttonSlug
              buttonUrl
              buttonText
              needsRefresh
              content
              fieldGroupName
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ThreeColumns {
            fieldGroupName
            columns {
              buttonSlug
              buttonText
              content
              fieldGroupName
              title
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ThreeColumnsGreen {
            bottomNotes
            fieldGroupName
            title
            animateStatNumbers
            columns {
              content
              fieldGroupName
              title
              buttonRequired
              buttonText
              buttonSlug
              titleNumber
              linkRefresh
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_CalloutButtons {
            fieldGroupName
            callout {
              buttonSlug
              buttonText
              content
              fieldGroupName
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_CalloutButtonsDownload {
            fieldGroupName
            callout {
              color
              fieldGroupName
              pdfOrFile {
                mediaItemUrl
              }
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1250)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContentByStats {
            buttonSlug
            buttonText
            content
            fieldGroupName
            statTitle
            title
            stats {
              fieldGroupName
              stat
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1250)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_Infographic {
            fieldGroupName
            title
            infographicMobile {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1250)
                }
              }
            }
            infographicDesktop {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_SimpleContentBlock {
            content
            fieldGroupName
            title
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContentWithImageLinks {
            content
            fieldGroupName
            title
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1250)
                }
              }
            }
            imageLinks {
              fieldGroupName
              linkSlug
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1250) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_Quote {
            fieldGroupName
            quoteAuthor
            quoteContent
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ImageBesideContentEven {
            contentBottom
            contentBottomTitle
            contentTop
            contentTopTitle
            fieldGroupName
            imageBottom {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1250)
                }
              }
            }
            imageTop {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1250)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_CalloutButtonsImage {
            fieldGroupName
            callout {
              color
              fieldGroupName
              slug
              title
              needRefresh
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1250)
                  }
                }
              }
            }
          }
          ... on WpPage_Acfmaintemplatefields_PageComponents_ImageByWysiwyg {
            buttonRequired
            buttonSlug
            buttonText
            fieldGroupName
            title
            wysiwyg
            topImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1250)
                }
              }
            }
            bottomRequired
            bottomImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1250)
                }
              }
            }
          }
          ... on WpPage_Acfmaintemplatefields_PageComponents_WysiwygWithQuote {
            fieldGroupName
            quote
            title
            wysiwyg
            bcActive
          }
          ... on WpPage_Acfmaintemplatefields_PageComponents_HeroSectionOne {
            content
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            fieldGroupName
          }
          ... on WpPage_Acfmaintemplatefields_PageComponents_WysiwygWithSubTitle {
            fieldGroupName
            subTitle
            title
            wysiwyg
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_BannerColorBg {
            content
            fieldGroupName
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
          ... on WpPage_Acfmaintemplatefields_PageComponents_ListWithContent {
            fieldGroupName
            title
            listPoints {
              content
              fieldGroupName
              title
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_SimpleContentImage {
            bcActive
            content
            fieldGroupName
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
          ... on WpPage_Acfmaintemplatefields_PageComponents_DisplayBod {
            display
            fieldGroupName
            intro
            title
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_DisplaySponsors {
            display
            fieldGroupName
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_DisplayCareers {
            display
            fieldGroupName
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_HeroImage {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_RepeatableImageByContent {
            fieldGroupName
            mainSubTitle
            mainTitle
            blocks {
              backgroundColor
              content
              fieldGroupName
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_RepeatableCircleContent {
            fieldGroupName
            blocks {
              content
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_Directory {
            fieldGroupName
            title
            resourceItems {
              category
              description
              fieldGroupName
              title
              websiteUrl
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_NewsAndEvents {
            display
            fieldGroupName
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContactForm {
            displayContactForm
            fieldGroupName
            formIntro
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_MailchimpForm {
            content
            displayMailchimpForm
            fieldGroupName
            title
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_WysiwygWithButton {
            buttonText
            buttonUrl
            fieldGroupName
            title
            wysiwyg
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_EmbedCode {
            embedCode
            fieldGroupName
          }
        }
      }
    }
  }
`

export default IndexPage
